import React from 'react';
import '../styles/EditContent.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const EditPage = () => {
  return (
    <>
      <Header />
      <main>
        <h1>Edit Content</h1>
        <p>Make changes to your content here.</p>
      </main>
      <Footer />
    </>
  );
};

export default EditPage;
