import React from 'react';
import '../styles/Profile.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Profile = () => {
  return (
    <>
      <Header />
      <main>
        <h1>Profile</h1>
        <p>Manage your account and view your purchases.</p>
      </main>
      <Footer />
    </>
  );
};

export default Profile;
