import React from 'react';
import '../styles/ContentPage.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const ContentPage = () => {
  return (
    <>
      <Header />
      <main>
        <h1>Content Page</h1>
        <p>View detailed content information here.</p>
      </main>
      <Footer />
    </>
  );
};

export default ContentPage;
