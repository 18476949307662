import '../styles/Catalog.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios'
import Header from '../components/Header';
import Footer from '../components/Footer';

const Catalog = () => {
  const [content, setContent] = useState([]);

  useEffect(() => {
    axios.get('https://rupmm.ru:1337/api/content')
      .then(response => setContent(response.data))
      .catch(error => console.log(error));
  }, []);  

return (
    <>
      <Header />
      <main>
        <h1>Catalog</h1>
         <ul>
          {content.map(item => (
            <li key={item.id}>{item.title}</li>
          ))}
        </ul>
      </main>
      <Footer />
    </>
  );
};

export default Catalog;
