import React from 'react';
import '../styles/Home.css';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <>
      <Header />
      <main>
        <h1>Welcome to Our Store</h1>
        <p>This is a brief description of the project.</p>
      <Link to="/catalog">Go to Catalog</Link>
      <br />
      <Link to="/profile">Go to Profile</Link>
      </main>
      <Footer />
    </>
  );
};

export default Home;
